<script setup>
import Nglottery from '../../components/nglottery/Index.vue'
</script>

<template>
    <div class="page">
      <!-- <div class="hm-logo">
        <img :src="require('../../assets/img/logo.png')" /> 
      </div> -->
      <div class="hm-des">
         <img :src="require('../../assets/img/mh-title.png')" /> 
     </div>
      <div class="hm-category">
     
        <div v-for="(item,i) in category_list" class="col" @click="buyQuotas(item)"> 
         <p>{{membercongig_data[item.id]}} </p> 
       </div>
      
     </div>
     <div class="hm-main">
      <Nglottery :categorylistdata="category_list"/>
      <audio src="" id="eventAudio"></audio>
      <audio src="" id="eventAudioBg"></audio>
    </div>
    <div class="hm-btns">
       <img  v-if="isPickup" :src="require('../../assets/img/btn-th.png')" @click="doPickUp" />
       <img  v-if="isSell" :src="require('../../assets/img/btn-js.png')"  @click="doSell" />
    </div>
    <div class="hm-list">
      <ul>
        <li v-for="(item,i) in toplist">
          <div class="avater">
            <!-- <img :src="item.avater" /> -->
          </div>
          <div  class="nickname">{{item.nickname}}</div>
          <div class="des">获得实物：{{item.productName}}{{item.amount}}{{item.unit}}</div>
       </li>
    </ul>
    </div>
    <div class="mh-zjxq">
      <img :src="require('../../assets/img/open-zjxq.png')"  @click="go_order"/>
    </div>

    </div>
</template>

<script>
export default{
		data(){
			return{
        id:'',
        isPickup:false,
        isSell:false,
        form:{},
        memberconfig_list:[],
        category_list:[],
        membercongig_data:{"1":"0","2":"0","3":"0"},
        lottery_data:{},
        toplist:[],
        musicUrl:'../../video/bjyl.mp3'
      }
		},
		mounted(){
      this.$EventBus.$on("lotteryDo", (res) => {
        console.log('EventBus:',res)
        window.localStorage.removeItem('lottery_data')
        window.localStorage.setItem('lottery_data',JSON.stringify(res))
        this.load_top_order()
        
        this.lottery_data=res
        this.isPickup= res.isPickup
        this.isSell= res.isSell
        this.load_memberconfig()
      });
      this.$EventBus.$on("clear_lotter", (res) => {
        window.localStorage.removeItem('lottery_data')
      });
      window.localStorage.removeItem('lottery_data') //第一次打开页面
      this.load_lottery_data()
      this.load_category()
			this.load_memberconfig()
      this.load_top_order()
    
      
		},
    beforeDestroy(){
      this.$EventBus.$emit("stopAudioBg");
    },
    destroyed(){
      this.$EventBus.$emit("stopAudioBg");
    },
		methods:{
      go_back(){
        this.$router.replace('/')
      },
      load_category(){
        let self=this
        this.$axios.get('/mhcategory/list')
        .then(res => {
          console.log('load_category:',res)
          self.category_list=res.data
          console.log('self.$store:',self.$store)
          // sessionStorage.setItem('msg', '6868')
          // sessionStorage.setItem('category_list',  self.category_list)
         //self.$store.state.category_list=self.category_list
          self.$store.commit('setCategory',self.category_list)

          //  console.log('msg:',sessionStorage.getItem('msg'))
          //  console.log('category_list:',sessionStorage.getItem('category_list'))
        })
      },
      load_memberconfig(){
        let self=this
        this.$axios.get('/mhmemberconfig/getmemberconfigbymemberid')
        .then(res => {
          console.log('load_memberconfig:',res)
          self.memberconfig_list=res.data
          if(res.type!='error'){
            self.memberconfig_list.forEach(function(item,i){
              self.membercongig_data[item.categoryId]=item.quotasCount
            })
            console.log('self.membercongig_data:',self.membercongig_data)
          }
        })
      },
      load_top_order(){
        let self=this
        this.$axios.get('/mhorder/toplist/3')
        .then(res => {
          console.log('load_top_order:',res)
          let json_data=res.data
          self.toplist=json_data
          console.log('self.toplist:',self.toplist)
        })
      },
      load_lottery_data(){
        let lottery_data=window.localStorage.getItem('lottery_data')
        if(lottery_data){
          this.lottery_data=JSON.parse(lottery_data)
          this.isPickup=  this.lottery_data.isPickup
          this.isSell=  this.lottery_data.isSell
        }
      },
      set_quotas(categoryId){
        let quotas_count=0
        this.memberconfig_list.forEach(function(item,i){
            if(item.categoryId==categoryId){
              quotas_count=item.quotasCount
            }
        })
        return quotas_count
      },
      buyQuotas(item){
        console.log('buyQuotas:',item)
       // this.$router.replace('/quotas?categoryId='+item.categoryId+'&price='+item.price)
       this.$router.push({path:'/quotas/'+item.id+'/'+item.price, params: {
            id: item.id,
            price:item.price
          }
        })
      },
      doPickUp(){
        console.log('this.lottery_data:',this.lottery_data)
        if(!this.lottery_data){
          this.$toast('请先抽奖')
          return
        }
        let id=this.lottery_data.orderId
        console.log('doPickUp:',id)
        this.$router.replace('/pickup?id='+id+'&p=1')
      },
      doSell(){
        if(!this.lottery_data){
          this.$toast('请先抽奖')
          return
        }

        let id=this.lottery_data.orderId
        this.$router.replace('/sell/?id='+id+'&p=1')
      },
      go_order(){
        console.log('go_order:',this.$router)
        this.$router.replace('/mhorder')
      },
      playAudioBg(){
        this.$EventBus.$emit("playAudioBg");
      },
      stopAudioBg(){
        this.$EventBus.$emit("stopAudioBg");
      }
    }
  }
</script>

<style scoped>
  .page{
      display:flex;
      flex-direction:column;
      flex-grow:1;
      justify-content: center;
      margin: 0px;
      padding: 0px;
      background: url('../../assets/img/mh-bg.png') no-repeat;
      background-size: cover;
  }
  .hm-category{
    display:inline-flex;
    flex-direction: row;
    width: 100%;
    height: 80px;
    justify-content: center;
  }
  .hm-category .col{
    width: 100px;
    height: 80px;
    position: relative;
  }
  .hm-category .col p{
    position: absolute;
    width: 100px;
    top:58px;
    left: 49px;
    color: #fff;
  }
  .hm-category div:nth-child(1){
    background: url('../../assets/img/bg-99.png') no-repeat;
    background-size: 80px;
    width: 80px;
    padding: 10px;
  }
  .hm-category div:nth-child(2){
    background: url('../../assets/img/bg-99.png') no-repeat;
    background-size: 80px;
    width: 80px;
    padding: 10px;
  }
  .hm-category div:nth-child(3){
    background: url('../../assets/img/bg-199.png') no-repeat;
    background-size: 80px;
    width: 80px;
    padding: 10px;
  }
  .hm-main{
    width: 100%;
    height: 400px;
    display:inline-flex;
    justify-content: center;
    margin: 0px;
    padding: 0px;
  }
  .hm-logo{
    width: 100%;
    height: 80px;
    display:inline-flex;
    justify-content: center;
  }
  .hm-logo img{
    width: 200px;
    height: 60px;
    margin: 20px;
  }
  .hm-des{
    width: 100%;
    height: 100px;
    display:inline-flex;
    justify-content: center;
  }
  .hm-des img{
    width: 300px;
    height: 80px;
    margin: 20px;
  }
  .hm-btns{
    width: 95%;
    height: 60px;
    display:inline-flex;
    justify-content: center;
  }
  .hm-btns img{
    width: 100px;
    height: 30px;
    margin: 10px;
  }
  .hm-list{
    width: 100%;
    height: 300px;
    margin: 0px;
    display:inline-flex;
    justify-content: center;
    flex-direction: row;
    background: url('../../assets/img/zj-list.png') no-repeat;
    background-size: 100%;
  }
  .hm-list ul{
    margin-top: 40px;
  }
  .hm-list li{
    width: 100%;
    height: 30px;
    display:inline-flex;
    justify-content: space-around;
    flex-direction:row;

  }
  .hm-list .avater{
    width: 0%;
    height: 20px;
    padding: 0px 10px 0px 10px;
    text-align: center;
  }
  .hm-list .avater img{
    width: 20px;
    height: 20px;
  }
  .hm-list .nickname{
    width: 30%;
    height: 30px;
  }
  .hm-list .des{
    width: 70%;
    height: 30px;
    white-space: nowrap;
    margin-top: -5px;
    padding: 0px;
  }
  .mh-zjxq{
    position:fixed;
    top: 100px;
    right: 0px;
  }
  .mh-zjxq img{
    width:30px;
    height: 100px;
  }
</style>
