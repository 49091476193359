
<template>
  <div>
    <van-nav-bar title="生肖转赠" left-text="返回" @click-left="go_back" left-arrow >
  <van-icon name="search" slot="right" />
</van-nav-bar>
<van-form @submit="onSubmit">
  <van-field
    v-model="form.zodiacName"
    type="label"
    name="zodiacName"
    label="生肖名称"
    readonly
  />
  <van-field
    v-model="form.toUsername"
    type="text"
    name="toUsername"
    label="接收账号"
    placeholder="请输入接收账号"
  />
  <van-cell title="转赠数量" value="" >
   <van-stepper v-model="form.amount" step="1"  label=""/>
  </van-cell>

  <div style="margin: 16px;">
    <van-button round block type="warning" native-type="submit">确定</van-button>
  </div>
</van-form>
</div>
</template>

<script>
let webview = null
export default{
		data(){
			return{
        id:'',
        form:{id:0,zodiacId:'',zodiacName:'',toUsername:'',amount:''},
        list:[]
      }
		},
		mounted(){
      console.log('this.$route.params:', this.$route.params)
  
			this.loadData()

      window.mhSetAddress=this.mhSetAddress
		},
		methods:{
      go_back(){
        this.$router.replace('/')
      },
      loadData(){
        let self=this
        self.form.id=self.$route.params.id
        self.form.zodiacName=self.$route.params.zodiacName
      },
    
      onSubmit(){
        let self=this
        if(!self.form.toUsername){
          self.$toast('请请输入接收用户账号！')
          return
        }
        if(!self.form.amount){
          self.$toast('请请输入转赠数量！')
          return
        }
        let toast=this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        self.$axios.post('/mhzodiaclottery/gifttomember/',{
          id:self.form.id,
          toUsername:self.form.toUsername,
          amount:self.form.amount,
        })
        .then(res => {
          console.log('onSubmit:',res)
          toast.clear()
          if(res.type=='error'){
            this.$toast(res.content)
          }else{
            let json_data=res.data.data
            this.$toast('转赠成功！')
            setTimeout(function(){
              self.$router.go(-1)
            },2000)
          }
        })
      },
      selectAddress(){
        webview = requireModuleJs("webview")
        webview.sendMessage('selectAddress')
      }
    }
  }
</script>

<style scoped>

</style>
