
<template>
  <div>
    <van-nav-bar title="购买抽奖名额" left-text="返回" @click-left="go_back" left-arrow >
     <van-icon name="search" slot="right" />
   </van-nav-bar>
<van-form @submit="onSubmit">
  <van-field
  aria-autocomplete="none"
  clickable
  name="picker"
  :value="form.categoryName"
  label="盲盒分类"
  placeholder="点击选择分类"
  @click="showPicker = true"
/>


  <van-cell title="价格" value=""  value-class="price">
    ¥{{form.price}}
  </van-cell>
  <van-cell title="购买数量" value="" >
   <van-stepper v-model="form.amount" step="1"  label="" @change="change_amount"/>
  </van-cell>
  <van-cell title="合计" value=""  value-class="price">
   ¥{{form.tatol_price}}
  </van-cell>
  <div class="cell-title">支付方式</div>
  <van-radio-group v-model="form.paymentPluginId" >
  <van-cell-group label="支付方式" inset>

    <van-cell title="余额支付" clickable @click="form.paymentPluginId = 'buyPointPayPlugin'">
      <template #right-icon>
        <van-radio name="buyPointPayPlugin"  />
      </template>
    </van-cell>
    <van-cell title="通证积分支付" clickable @click="form.paymentPluginId = 'buyTokenPayPlugin'">
      <template #right-icon>
        <van-radio name="buyTokenPayPlugin"  />
      </template>
    </van-cell>
    <!-- <van-cell title="微信支付" clickable @click="form.paymentPluginId = 'weixinAppPayPlugin'">
      <template #right-icon>
        <van-radio name="weixinAppPayPlugin" />
      </template>
    </van-cell> -->
    <van-cell title="支付宝支付" clickable @click="form.paymentPluginId = 'alipayAppPlugin'">
      <template #right-icon>
        <van-radio name="alipayAppPlugin" />
      </template>
    </van-cell>
  </van-cell-group>
</van-radio-group>
  <div style="margin: 16px;">
    <van-button round block type="warning" native-type="submit">立即购买</van-button>
  </div>
</van-form>
<van-popup v-model="showPicker" position="bottom">
  <van-picker
    show-toolbar
    :columns="category_columns"
    @confirm="onConfirm"
    @cancel="showPicker = false"
  />
</van-popup>
</div>
</template>

<script>
let webview = null
export default{
		data(){
			return{
        id:'',
        sn:'',
        form:{categoryId:'',categoryName:'',price:'',tatol_price:'',amount:'',paymentPluginId:'buyPointPayPlugin'},
        category_columns:[],
        category_list:[],
        showPicker:false,
        checkPayTimer:'',
      }
		},
		mounted(){
      console.log('this.$route.params:', this.$route.params)
      console.log('this.$route.query:', this.$route.query)
      console.log('this.$store.state.category_list:', this.$store.state.category_list)
      
      this.form.categoryId=this.$route.params.categoryId
      this.form.price=this.$route.params.price
      this.form.tatol_price=this.$route.params.price*1
      this.category_list= this.$store.state.category_list
      this.load_category()
			this.load_form()
		},
		methods:{
      go_back(){
        this.$router.replace('/')
      },
      load_category(){
        let self=this
        this.category_list.forEach(element => {
          console.log('element:',element)
          self.category_columns.push(element.name)
          if(element.id==self.form.categoryId){
            self.form.categoryName=element.name
          }
        });
       
        console.log('this.form.categoryId:',this.form.categoryId)
        if(!this.form.categoryName&&this.category_list.length>0){
          this.form.categoryId=this.category_list[0].id
          this.form.categoryName=this.category_list[0].name
          this.form.price=this.category_list[0].price
          this.form.tatol_price=this.category_list[0].price*1
        }
      },
      load_form(){
       
      },
      checkPay(){
        let self=this
        self.checkPayTimer=setInterval(function(){
          self.$axios.get('/mhquotasorder/findorder/'+self.sn)
          .then(res => {
            console.log('checkPay:',res)
            let order=res.data
            //self.$toast('order:'+JSON.stringify(order))
            if(order.status==1){
              clearInterval(self.checkPayTimer)
              self.$toast('恭喜，支付成功！')
              self.$dialog.close()
              setTimeout(function(){
                self.$router.replace('/')
              },2000)
            }
          })
        },2000)
      },
      onSubmit(){
        let self=this
        if(!self.form.categoryId){
          this.$toast('请选择盲盒分类！')
          return
        }
        if(!self.form.paymentPluginId){
          this.$toast('请选择支付方式！')
          return
        }
        let toast=this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        this.$axios.post('/mhquotasorder/addorder',
        {
          categoryId:self.form.categoryId,
          amount:self.form.amount,
          paymentPluginId:self.form.paymentPluginId
        })
        .then(res => {
          console.log('onSubmit:',res)
          if(res.type=='error'){
            self.$toast(res.content)
          }else{
            let json_data=res.data
            self.sn=json_data.sn
            self.$toast('json_data:'+res.data.sn)
            if(self.form.paymentPluginId=='weixinAppPayPlugin'){
               self.$toast('跳转支付中...')
               self.checkPay()
               self.$dialog.confirm({
                title: '微信支付',
                message: '请您在微信完成付款',
                confirmButtonText:'完成支付',
                cancelButtonText:'关闭',
              })
                .then(() => {
                  // on confirm
                  self.$router.replace('/')
                })
                .catch(() => {
                  // on cancel
                 // self.$router.replace('/')
                });
               webview = requireModuleJs("webview")
               webview.sendMessage('weixinpay:'+res.data.sn)
            }else if(self.form.paymentPluginId=='alipayAppPlugin'){
               self.$toast('跳转支付中...')
               self.checkPay()
               self.$dialog.confirm({
                title: '支付宝支付',
                message: '请您在支付宝完成付款',
                confirmButtonText:'完成支付',
                cancelButtonText:'关闭',
                })
                .then(() => {
                  // on confirm
                  self.$router.replace('/')
                })
                .catch(() => {
                  // on cancel
                 // self.$router.replace('/')
                });
               webview = requireModuleJs("webview")
               webview.sendMessage('alipay:'+res.data.sn)
            }
            else{
               self.$toast('购买成功！')
               setTimeout(function(){
                  self.$router.replace('/')
                  toast.clear()
                },2000)
              
            }
           
           }
        
        })
      },
      onConfirm(value){
        let self=this
        this.category_list.forEach(element => {
          console.log('element:',element)
          if(element.name==value){
            self.form.categoryId=element.id
            self.form.price=element.price
            self.form.tatol_price= self.form.price*self.form.amount
          }
        });
        this.form.categoryName=value
        this.showPicker=false
      },
      change_amount(value){
        console.log('change_amount:',value)
        let self=this
        self.form.tatol_price= self.form.price*value
      }
    }
  }
</script>

<style scoped>
.cell-title{
  padding: 0px 20px 0px 20px;
  font-weight: bold;
}
.price{
  color: chocolate;
}
</style>
