
<template>
  <div>
    <van-nav-bar title="提货" left-text="返回" @click-left="go_back" left-arrow >
  <van-icon name="search" slot="right" />
</van-nav-bar>
<van-form @submit="onSubmit">
  <van-field
    v-model="form.productName"
    type="label"
    name="producName"
    label="商品名称"
    readonly
  />
  <van-field
    v-model="form.price"
    type="label"
    name="price"
    label="价格"
    readonly
  />
  <van-field
    v-model="form.amount"
    type="label"
    name="amount"
    label="数量"
    readonly
  />
  <van-cell title="选择地址" is-link  @click="selectAddress">
    {{address_data.consignee}}  {{address_data.phone}}
    {{address_data.address}}
 </van-cell>
  <div style="margin: 16px;">
    <van-button round block type="warning" native-type="submit">提交</van-button>
  </div>
</van-form>
</div>
</template>

<script>
let webview = null
export default{
		data(){
			return{
        id:'',
        form:{receiverId:'',receiverName:'',receiverPhone:'',receiverAddress:''},
        address_data:{},
        address_text:'',
        list:[],
        pre_page:''
      }
		},
		mounted(){
      console.log('this.$route.query:', this.$route.query)
      this.id=this.$route.query.id
      this.pre_page=this.$route.query.p
      console.log('id:', this.id)
			this.loadData()

      window.mhSetAddress=this.mhSetAddress
		},
		methods:{
      go_back(){
        this.$router.replace('/')
      },
      loadData(){
        let self=this
        this.$axios.get('/mhorder/find/'+this.id)
        .then(res => {
          console.log('loadData:',res)
          let json_data=res.data.mHOrder
          self.form=json_data

        })
      },
      mhSetAddress(data){
        this.address_text=data
        let json_data=JSON.parse(data)
        this.address_data=json_data
        this.form.receiverId=json_data.receiverId
        this.form.receiverName=json_data.consignee
        this.form.receiverPhone=json_data.phone
        this.form.receiverAddress=json_data.address
      },
      onSubmit(){
        let self=this
        if(!this.form.receiverId){
          self.$toast('请选择提货地址！')
          return
        }
        let toast=this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        this.$axios.post('/mhorder/pickup',{
           id:this.id,
           receiverId:this.form.receiverId,
           receiverName:this.form.receiverName,
           receiverPhone:this.form.receiverPhone,
           receiverAddress:this.form.receiverAddress,
        })
        .then(res => {
          console.log('onSubmit:',res)
          toast.clear()
          if(res.type=='error'){
            this.$toast(res.content)

          }else{
            let json_data=res.data.data
            this.$EventBus.$emit("clear_lotter", 1);
            self.$toast('提交交割成功，请等待发货！')
            setTimeout(function(){
              self.$router.replace('/mhorder')
            },2000)
          }
        
        })
      },
      selectAddress(){
        webview = requireModuleJs("webview")
        webview.sendMessage('selectAddress')
      }
    }
  }
</script>

<style scoped>
.address-info{
  width: 300px;
  padding: 20px;
}
</style>
