<template>
  <div id="app">
    <router-view>
      <img alt="Vue logo" src="./assets/logo.png">
      <Home/>
      <van-toast id="van-toast" />
    </router-view>
  </div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import Home from './pages/home/Index.vue'
import MHOrder from './pages/mhorder/Index.vue'
export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 40px 0px 0px 0px;
  padding: 0px;
}
</style>
