
const getApiroot=()=> {
  var api_root = '';
  if (process.env.NODE_ENV) {  // 开发环境
    api_root = "http://api.rzico.niao.ltd:8080"//http://192.168.0.106:8080 https://api.jxd.qmin365.cn:28446  https://pay.qmin365.cn:9090"
  }else {  // 生产环境
    api_root = "http://api.rzico.niao.ltd:8080"
  }
  return api_root
}
const siteinfo= {
  api_root:getApiroot()
}
export default siteinfo
