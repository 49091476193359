//1. 导入 Vue 和 VueRouter 的包
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/home/Index.vue'
import MHOrder from '../pages/mhorder/Index.vue'
import MHPickup from '../pages/mhorder/Pickup.vue'
import MHSell from '../pages/mhorder/Sell.vue'
import MHQuotas from '../pages/quotas/Index.vue'
import MHGift from '../pages/mhorder/Gift.vue'
import MHTest from '../pages/test/Index.vue'
 
// const originalPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err)
// }
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};


//2. 调用Vue.use()函数，把VueRouter安装为Vue的插件
Vue.use(VueRouter)
 
//3. 创建路由的实例对象
const router = new VueRouter({
    //routes是一个数组，定义hash地址与组件之间的关系
    routes: [
        {path: '/', component:Home,hidden: true },
        {path: '/home', component:Home,hidden: true },
        {path: '/mhorder', component:MHOrder,hidden: true, props:true },
        {path: '/pickup', component:MHPickup,hidden: true, props:true },
        {path: '/sell', component:MHSell,hidden: true, props:true },
        {path: '/quotas/:categoryId/:price', component:MHQuotas,hidden: true, props:true },
        {path: '/gift/:id/:zodiacName', component:MHGift,hidden: true, props:true },
        {path: '/test', component:MHTest,hidden: true, props:true },
    ]
})

 
//4. 向外共享路由的实例对象
export default router