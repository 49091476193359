
<template>
  <div>
    <van-nav-bar title="返回" left-text="返回" @click-left="go_back" left-arrow >
  <van-icon name="search" slot="right" />
</van-nav-bar>
<van-form @submit="onSubmit">
  <van-field
    v-model="form.productName"
    type="label"
    name="producName"
    label="商品名称"
    readonly
  />
  <van-field
    v-model="form.price"
    type="label"
    name="price"
    label="原价格"
    readonly
  />
  <van-field
    v-model="form.sellPrice"
    type="label"
    name="sellPrice"
    label="寄售价格"
    readonly
  />
  <van-field
    v-model="form.amount"
    type="label"
    name="amount"
    label="数量"
    readonly
  />
  <div style="margin: 16px;">
    <van-button round block type="warning" native-type="submit">提交</van-button>
  </div>
</van-form>
</div>
</template>

<script>
let webview = null
export default{
		data(){
			return{
        id:'',
        form:{},
        address_data:{},
        address_text:'',
        list:[],
        pre_page:''
      }
		},
		mounted(){
      console.log('this.$route.query:', this.$route.query)
      this.id=this.$route.query.id
      this.pre_page=this.$route.query.p
      console.log('id:', this.id)
			this.loadData()
		},
		methods:{
      go_back(){
        this.$router.replace('/')
      },
      loadData(){
        let self=this
        this.$axios.get('/mhorder/find/'+this.id)
        .then(res => {
          console.log('loadData:',res)
          let json_data=res.data.mHOrder
          self.form=json_data

        })
      },
      onSubmit(){
        let self=this
        let toast=this.$toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        this.$axios.get('/mhorder/sell/'+this.id)
        .then(res => {
          console.log('onSubmit:',res)
          toast.clear()
          if(res.type=='error'){
            this.$toast(res.content)
          }else{
            this.$EventBus.$emit("clear_lotter", 1);
            let json_data=res.data.data
            self.$toast('提交寄售成功！')
            setTimeout(function(){
              self.$router.replace('/mhorder')
             
            },2000)
          }
          
        })
      }
    }
  }
</script>

<style scoped>

</style>
