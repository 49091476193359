
// import Vue from 'vue'
// import Vuex from 'vuex'
// import getters from './getters'
// Vue.use(Vuex)
// // https://webpack.js.org/guides/dependency-management/#requirecontext
// const modulesFiles = require.context('./modules', true, /\.js$/)
// // 它将自动要求模块文件中的所有vuex模块
// // you do not need `import app from './modules/app'`
// // it will auto require all vuex module from modules file
// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
//   // set './app.js' => 'app'
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
//   const value = modulesFiles(modulePath)
//   modules[moduleName] = value.default
//   return modules
// }, {})
// const store = new Vuex.Store({
//   modules,
//   getters,
// })
// export default store


import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

var store = new Vuex.Store({
      state:{    //在state对象建立需要数据
        category_list:[]
      },
      mutations:{    
         setCategory:function(state,data){
          console.log('state:',state)
          console.log('data:',data)
          state.category_list=data
         }
      }
  })

  export default store