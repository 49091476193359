
<template>
  <div>
    <van-nav-bar title="中奖详情" left-text="返回" @click-left="go_back" left-arrow >
  <van-icon name="search" slot="right" />
</van-nav-bar>
<van-tabs v-model="active" @click="onTabClick">
  <van-tab title="抽奖记录">
    <van-pull-refresh v-model="isLoadingCjlist" @refresh="load_cjlist">
      <div v-for="(item,i) in list">
      <van-panel :title="item.productName"  :status="setStatusText(item.status)">
        <div class="pnl-content">
        <div class="pnl-content-left"><img :src="item.imgUrl"  class="img-url"/>
        </div> 
          <div class="pnl-content-right"> 
            价格：{{item.price}} 数量：{{item.amount}} 
            <div v-if="item.status==1||item.status==3||item.status==4">
              收货地址：{{item.receiverName}}{{item.receiverPhone}}{{item.receiverAddress}}
            </div>
          </div> 
        </div>
        <div slot="footer" class="pnl-content-btns">
          <div class="left">{{(item.createDate+'').substring(0,10) | formatDate}}</div>
          <div  class="right"><van-button size="small" class="btn" v-if="item.isPickup&&item.status==0" @click="doPickUp(item.id)">提货</van-button>
          <van-button size="small" class="btn" v-if="item.isSell&&item.status==0" type="danger" @click="doSell(item.id)">寄售</van-button>
        </div>
        </div>
      </van-panel>
    </div>
    <div class="empty-list" v-if="list.length==0">
            暂无数据
    </div>
   </van-pull-refresh>


  </van-tab>
  <van-tab title="生肖奖励">
    <div class="page-main">
    <div class="page-left">
      <van-sidebar v-model="activeKey" @change="categoryChange">
        <van-sidebar-item :title="item.name"  :key="item.id" v-for="(item) in category_list"/>
      </van-sidebar>
    </div>
    <div class="page-right">
      <div class="pnl-top">
      <div class="pnl-top-l">资金池：{{zodiacMoney}}</div>
      <div class="pnl-top-c"><van-button size="small"    type="danger"  @click="doFull(item)">集满合成</van-button></div>
      <div class="pnl-top-r">提示：集满12生肖获取资金池{{fullZodiacrate}}%的利润</div>
      </div>
      <van-pull-refresh v-model="isLoadingSxlist" @refresh="load_sxlist">
        <div v-for="(item,i) in zodiac_reward_list">
        <van-panel  >
          <div class="pnl-content">
          <div class="pnl-content-left"><img :src="item.zodiacImgurl"  class="img-url"/>
          </div> 
            <div class="pnl-content-right"> 生肖名称：{{item.zodiacName}}<br/> 数量：{{item.amount}} </div> 
          </div>
          <div slot="footer" class="pnl-content-btns"  v-if="item.zodiacName=='兔'">
            <div class="left"></div>
            <div  class="right">
              <van-button size="small" class="btn"   type="danger" v-if="item.zodiacName=='兔'" @click="doGift(item)">转赠</van-button>
            </div>
          </div>
        </van-panel>
      </div>
      <div class="empty-list" v-if="zodiac_reward_list.length==0">
              暂无数据
      </div>

      </van-pull-refresh>
    </div>
  </div>
  </van-tab>
</van-tabs>


</div>
</template>

<script>
import {formatDate} from '../../utils/formatDate.js'
export default{
    filters: {
      formatDate(time) {
        time = time * 1000
        let date = new Date(time)
        console.log(new Date(time))
        return formatDate(date, 'yyyy-MM-dd hh:mm')
      }
    },
		data(){
			return{
        active:'1',
        activeKey:0,
        tab_index:0,
        pageNum:1,
        pageSize:10,
        allWinMoney:0,
        zodiacMoney:0,
        fullZodiacrate:0,
        categoryId:1,
        isLoadingCjlist:false,
        isLoadingSxlist:false,
        list:[],
        zodiac_reward_list:[],
        category_list:[]
      }
		},
		mounted(){
      this. load_category()
		  this.load_data()
		},
		methods:{
      go_back(){
        this.$router.replace('/')
      },
      load_category(){
        let self=this
        this.$axios.get('/mhcategory/list')
        .then(res => {
          console.log('load_category:',res)
          self.category_list=res.data
          self.categoryId= self.category_list[0].id;
        })
      },
      load_config(){
        let self=this
        self.$axios.get('/mhzodiaclottery/getconfig?categoryId='+ this.categoryId)
        .then(res => {
          console.log('load_config:',res)
          self.zodiacMoney=res.data.zodiacMoney
          self.fullZodiacrate=res.data.fullZodiacrate
          console.log(' self.allWinMoney:', self.allWinMoney)
        })
      },
      load_data(){
        if(this.tab_index==0){
          this.load_cjlist()
        }else if(this.tab_index==1){
          this.load_sxlist()
          this.load_config()
        }
      },
      load_cjlist(){
        let self=this
        self.isLoadingCjlist=true
        self.$axios.get('/mhorder/list?pageNum='+self.pageNum+'&pageSize='+self.pageSize)
        .then(res => {
          console.log('load_cjlist:',res)
        
          let json_data=res.data.data
          if(self.pageNum==1){
            self.list=json_data
          }else{
            self.list=self.list.concat(json_data)
          }
          self.isLoadingCjlist=false
          if(json_data.length==0){
            self.pageNum=1
          }else{
            self.pageNum+=1
          }
        })
      },
      load_sxlist(){
        let self=this
        self.$axios.get('/mhzodiaclottery/ownlist?pageNum='+self.pageNum+'&pageSize='+self.pageSize+'&categoryId='+ this.categoryId)
        .then(res => {
          console.log('load_sxlist:',res)
          let json_data=res.data.data
          if(self.pageNum==1){
            self.zodiac_reward_list=json_data
          }else{
            self.zodiac_reward_list = self.zodiac_reward_list.concat(json_data)
          }
          self.isLoadingSxlist=false
          if(json_data.length==0){
            self.pageNum=1
          }else{
            self.pageNum+=1
          }
        })
      },
      onTabClick(data){
        console.log('onTabClick:',data)
        this.tab_index=data
        this.pageNum=1
        this.load_data()
      },
      doPickUp(id){
        console.log('doPickUp:',id)
        this.$router.replace('/pickup?id='+id+'&p=2')
      },
      doSell(id){
        this.$router.replace('/sell/?id='+id+'&p=2')
      },
      setStatusText(status){
        console.log('setStatusText:',status)
        status=parseInt(status)
        if(status==1){
          return "已提交申请，待发货"
        }else if(status==2){
          return "已寄售"
        }else if(status==3){
          return "已发货"
        }else if(status==4){
          return "已完成"
        }
        else if(status==0){
          return "新中奖"
        }else{
          return ""
        }
      },
      doGift(item){
        this.$router.push({path:'/gift/'+item.id+'/'+item.zodiacName, params: {
             id: item.id,
            zodiacName:item.zodiacName
          }
        })
      },
      doFull(){
        let self=this

        self.$axios.get('/mhzodiaclottery/dofull?categoryId='+this.categoryId)
        .then(res => {
          console.log('doFull:',res)
          if(res.type=='error'){
             this.$toast(res.content)
          }else{
             let winMoney=res.data.winMoney
             self.$dialog.alert({
                title: '中奖提示',
                message: '恭喜您！获得：'+winMoney+'的大奖！！！',
              }).then(() => {
                // on close
              })
          }

        })
      },
      categoryChange(index){
        this.categoryId=this.category_list[index].id
        this.pageNum=1
        this.zodiac_reward_list=[]
        this.load_config()
        this.load_sxlist();
      }
    }
  }
</script>

<style scoped>
.pnl-content{
  display: inline-flex;
  padding: 10px;
  flex-direction:row;
}
.pnl-content-left{
  width: 100px;
  height: 80px;
}
.pnl-content-right{
  width: 70%;
  height: 80px;
  padding:0px 20px 0px 20px;
}
.pnl-content-left .img-url{
  width: 80px;
  height: 80px;
}
.pnl-content-btns{
  display: inline-flex;
  justify-content:space-around;
  width: 100%;
}
.pnl-content-btns .btn{
  margin: 5px;
}
.pnl-content-btns .left{
  width: 40%;
}
.pnl-content-btns .right{
  width: 60%;
  text-align: right;
}
.pnl-top{
  width: 100%;
  height: 50px;
  display: inline-flex;
  justify-content:center;
  padding: 10px;
  flex-direction: row;
}
.pnl-top-l{
  width: 30%;
  text-align: left;
}
.pnl-top-c{
  width: 20%;
}
.pnl-top-r{
  width: 30%;
  text-align: left;
  font-size: 10px;
}
.page-main{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}
.page-left{
  width: 100px;
  height: 100%;
}
.page-right{
  width: auto;
  height: 100%;
}
</style>
