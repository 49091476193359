<template>
 <div class="lottery-box" @click="playAudioBg">
  <div class="lottery">
    <div class="lottery-item">
      <div class="lottery-start">
        <div class="box gray" v-if="isStart===0">
          <p>活动未开始</p>
        </div>
        <div :class="!isOpened?'box':'box-z'" @click="startLottery" v-if="isStart===1">
          <p><img src="../../assets/img/btn-cj.png" /></p>
          <p>消耗{{score}}积分</p>
        </div>
        <div class="box gray" v-if="isStart===2">
          <p>活动已过期</p>
        </div>
      </div>
      <ul>
        <li v-for="(item,i) in list" :class="i==index?'on':''">
          <div :class="!isOpened?'box':'box-z '+(i==index?'on':'')">
            <p><img :src="item.img" alt=""  v-if="i!=index" /><img :src="item.img_on" alt="" :class="i==index?'on':''"  v-if="i==index"/></p>
          </div>
        </li>
      </ul>
    </div>
  </div>  
<!-- 中奖弹窗 -->
<div class="mask" v-if="showToast"></div>
<div class="lottery-alert" v-if="showToast">
   <h1>恭喜您</h1>
   <p><img :src="lottery_data.imgUrl" alt=""></p>
   <h2>获得{{lottery_data.productName}}</h2>
   <div class="btnsave" @click="showToast=false" >确定</div> 
</div>  

<van-popup v-model="showPicker" position="bottom">
  <van-picker
    show-toolbar
    :columns="category_columns"
    @confirm="onConfirm"
    @cancel="showPicker = false"
  />
</van-popup>
<audio controls="controls" ref="audioBg" hidden>
  <source  type="audio/ogg">
  <source :src="musicUrl" type="audio/mpeg">
</audio>

</div>

</template>

<script>
import { TypedChainedSet } from 'webpack-chain'

   let webview=null
	export default{
		name:"will",
    props:{
      categorylistdata:[]
    },
		data(){
			return{
        isStart:1,	
        score:10, //消耗积分
        list:[
        {img:new URL('../../assets/img/12/tu.png', import.meta.url).href,img_on:new URL('../../assets/img/12-1/tu-act.png', import.meta.url).href,title:''},
        {img:new URL('../../assets/img/12/hu.png', import.meta.url).href,img_on:new URL('../../assets/img/12-1/hu-act.png', import.meta.url).href,title:''},
        {img:new URL('../../assets/img/12/niu.png', import.meta.url).href,img_on:new URL('../../assets/img/12-1/niu-act.png', import.meta.url).href,title:''},
        {img:new URL('../../assets/img/12/shu.png', import.meta.url).href,img_on:new URL('../../assets/img/12-1/shu-act.png', import.meta.url).href,title:''},
        {img:new URL('../../assets/img/12/zhu.png', import.meta.url).href,img_on:new URL('../../assets/img/12-1/zhu-act.png', import.meta.url).href,title:''},
        {img:new URL('../../assets/img/12/gou.png', import.meta.url).href,img_on:new URL('../../assets/img/12-1/gou-act.png', import.meta.url).href,title:''},
        {img:new URL('../../assets/img/12/ji.png', import.meta.url).href,img_on:new URL('../../assets/img/12-1/ji-act.png', import.meta.url).href,title:''},
        {img:new URL('../../assets/img/12/hou.png', import.meta.url).href,img_on:new URL('../../assets/img/12-1/hou-act.png', import.meta.url).href,title:''},
        {img:new URL('../../assets/img/12/yang.png', import.meta.url).href,img_on:new URL('../../assets/img/12-1/yang-act.png', import.meta.url).href,title:''},
        {img:new URL('../../assets/img/12/ma.png', import.meta.url).href,img_on:new URL('../../assets/img/12-1/ma-act.png', import.meta.url).href,title:''},
        {img:new URL('../../assets/img/12/she.png', import.meta.url).href,img_on:new URL('../../assets/img/12-1/she-act.png', import.meta.url).href,title:''},
        {img:new URL('../../assets/img/12/long.png', import.meta.url).href,img_on:new URL('../../assets/img/12-1/long-act.png', import.meta.url).href,title:''}
        ],   //奖品1-12
        win_imgs:[
          'https://mang.qmin365.cn/img/jiu/101.png',
          'https://mang.qmin365.cn/img/jiu/102.png',
          'https://mang.qmin365.cn/img/jiu/103.png',
          'https://mang.qmin365.cn/img/jiu/104.png',
          'https://mang.qmin365.cn/img/jiu/105.png',
          'https://mang.qmin365.cn/img/jiu/106.png',
          'https://mang.qmin365.cn/img/jiu/107.png'
        ],
        temp_list:[],     
        index: -1,  // 当前转动到哪个位置，起点位置
        count: 12,  // 总共有多少个位置
        timer: 0,  // 每次转动定时器
        speed: 200,  // 初始转动速度
        times: 0,    // 转动次数
        cycle: 50,   // 转动基本次数：即至少需要转动多少次再进入抽奖环节
        prize: -1,   // 中奖位置
        click: true,
        showToast: false, //显示中奖弹窗  
        username:'',
        categoryId:'1',
        tmptoken:'',
        response:'',
        lottery_data:{},
        goods_list:[],
        isPickup:false,
        isSell:false,
        isLoading:false,
        isOpened:false,
        memberconfig_list:[],
        category_columns:[],
        category_list:[],
        showPicker:false,
        cates_count:3,
        show_music:false,
        musicUrl:'../../video/bjyl.mp3'
        }
		},
		mounted(){
      this.$EventBus.$on("playAudioBg", (res) => {
        this.$refs.audioBg.src=require('../../assets/video/bjyl.mp3')
        this.$refs.audioBg.play()
      })
      this.$EventBus.$on("stopAudioBg", (res) => {
       // this.$refs.audioBg.src=''
        this.$refs.audioBg.parse()
      })
      window.localStorage.setItem('sx_list',JSON.stringify(this.list))
      this.category_list= this.categorylistdata
			this.userinfo()
      this.goodsList()
      this.load_category()
      this.getMemberconfig()
      window.setUserinfo=this.userinfo
     // window.pauseAudio=this.pauseAudio
     // this.$refs.audioBg.src=require('../../assets/video/bjyl.mp3')
    
		},
		methods:{
      userinfo(){
        let self=this
        let token=window.localStorage.getItem('token')
        self.tmptoken = token
        this.$axios.defaults.headers.common['Authorization'] = token
       // console.log('token8:',token)
        this.$axios.get('/member/getInfo').then(res => {
          console.log('getInfo:',res)

          let json_data=res.data
          let member=json_data.member
          self.username=member.name
        })
      },
     
      goodsList(){
        let self=this
        this.$axios.get('/mhgoods/goodslist').then(res => {
          console.log('goodsList:',res)
          let json_data=res.data
          self.goods_list=json_data
          console.log('self.goods_list:',self.goods_list)
        })
      },
      load_category(){
        let self=this
        this.$axios.get('/mhcategory/list')
        .then(res => {
          self.category_list=res.data
          self.$store.commit('setCategory',self.category_list)
          self.category_list.forEach(element => {
          self.category_columns.push(element.name)
        })
        })
      },
      onConfirm(value){
        let self=this
        self.category_list.forEach(element => {
          if(element.name==value){
            self.categoryId=element.id
            self.doLottery()
          }
        });
        this.showPicker=false
      },
      getMemberconfig(){
        let self=this
        //获得个人是否有额度
        self.$axios.get('/mhmemberconfig/getmemberconfigbymemberid')
          .then(res => {
            console.log('load_memberconfig:',res)
            self.memberconfig_list=res.data
            if(res.type!='error'){
              let c=0
              self.memberconfig_list.forEach(function(item,i){
                if(item.quotasCount>0){
                  c=c+1
                }
              })
              self.cates_count=c;
             
            }
          })
      },
      checkQuatas(){
        let self=this
        //获得个人是否有额度
        if(self.cates_count>1){
          self.showPicker=true
        }else{
          self.categoryId=0
          self.doLottery()
        }
      },
      playAudioBg(){
        let self=this
        self.$refs.audioBg.play()
       // self.$refs.audioBg.loop(true);
        //  webview = requireModuleJs("webview")
        //  webview.sendMessage('playAudio$http://192.168.0.106/video/bjyl.mp3')
      },
      playAudioCj(){
        //  webview = requireModuleJs("webview")
        //  webview.sendMessage('playAudio$http://192.168.0.106/video/cjyl.mp3')
      },
      pauseAudio(){
        self.$refs.audioBg.pause()
        //  webview = requireModuleJs("webview")
        //  webview.sendMessage('pauseAudio')
      },
      stopAudio(){
        //  webview = requireModuleJs("webview")
        //  webview.sendMessage('stopAudio')
      },
      doLottery(){
        let self=this
        try {
          if(self.isLoading&&self.username){
            this.$toast('抽奖中...')
            return
          }
          
          console.log('self.temp_list:',self.temp_list)
          self.list=JSON.parse( window.localStorage.getItem('sx_list')) 
          self.isOpened=false
          self.isLoading=true

          //播放音乐
          // this.stopAudio();
          // this.playAudioCj();
          setTimeout(function(){
            self.$refs.audioBg.src=require('../../assets/video/cjyl.mp3')
            self.$refs.audioBg.play()

          },100);
     
          //正式抽奖
          this.$axios.post('/mhgoods/lottery',{
            categoryId:this.categoryId
          },{
            headers:{'Content-Type':'application/x-www-form-urlencoded'}
          }
          ).then(res => {
            console.log('doLottery:',res)
            // this.$toast(JSON.stringify(res))
             self.isLoading=false
            if(res.type=='error'){
              if(res.content.toString().indexOf('授权')>0){
                if(!webview)
                  webview = requireModuleJs("webview")
                webview.sendMessage('login')
              }
              this.$toast(res.content)
              if(res.content.toString().indexOf('不足')>0){
               
                setTimeout(() => {
                  this.$router.push({path:'/quotas/0/0', params: {
                        id: '',
                        price:''
                      }
                    })
                }, 1000);
              }
              
            }else{
              let json_data=res.data
              self.lottery_data=json_data
              self.getMemberconfig();
              self.startRoll(); 
            }
          })
        }
        catch(err) {
          console.log('抽奖发生异常:',err)
          self.isLoading=false
          return;
        }
        finally {
         
        }

      },
      go_order(){
        console.log('go_order:',this.$router)
        this.$router.replace('/mhorder')
      },
      startLottery(){
        if (!this.click) { return }
        this.checkQuatas()
       // this.doLottery();
      },		
      // 开始转动
      startRoll () {
        let self=this
        this.times += 1 // 转动次数
        this.oneRoll() // 转动过程调用的每一次转动方法，这里是第一次调用初始化 
        // 如果当前转动次数达到要求 && 目前转到的位置是中奖位置
        if (this.times > this.cycle + 10 && this.prize === this.index) {
          clearTimeout(this.timer)  // 清除转动定时器，停止转动
          this.prize = -1
          this.times = 0
          this.speed = 200
          this.click = true; 
          var that = this;
          setTimeout(res=>{
            that.showToast = true;
            this.isPickup= this.lottery_data.isPickup
            this.isSell= this.lottery_data.isSell
            this.isLoading=false
            this.isOpened=true
            this.list[this.index].img_on=this.lottery_data.imgUrl
            this.$EventBus.$emit("lotteryDo", this.lottery_data);
            //抽奖结束继续播放背景音乐
    
            setTimeout(function(){
              // self.$refs.audioBg.src=require('../../assets/video/bjyl.mp3')
              // self.$refs.audioBg.play()
              // self.$refs.audioBg.loop(true);
              self.$refs.audioBg.src=''
              self.$refs.audioBg.pause()

            },100);
     
            this.list.forEach(function(item,i){
                if(i!=self.index){
                  if(self.goods_list.length>i){
                    if(self.goods_list[i].imgUrl)
                      self.list[i].img=self.goods_list[i].imgUrl
                  }else{
                    // if(self.goods_list[0].imgUrl)
                    //   self.list[i].img=self.goods_list[0].imgUrl
                    self.list[i].img=self.win_imgs[parseInt(Math.random()* self.win_imgs.length-1)]
                  }
                }
            })
           
          },500)			                  
        } else {
          if (this.times < this.cycle) {
          this.speed -= 10  // 加快转动速度
          } else if (this.times === this.cycle) { 
          const index = parseInt(Math.random() * 10, 0) || 0;  // 随机获得一个中奖位置
                this.prize = index; //中奖位置,可由后台返回 
          if (this.prize > 7) { this.prize = 7 }
          } else if (this.times > this.cycle + 10 && ((this.prize === 0 && this.index === 7) || this.prize === this.index + 1)) {
          this.speed += 110
          } else {
          this.speed += 20
          }      
          if (this.speed < 40) {this.speed = 40}
          this.timer = setTimeout(this.startRoll, this.speed)
        }
      },
      // 每一次转动
      oneRoll () {
        let index = this.index // 当前转动到哪个位置
        const count = this.count // 总共有多少个位置
        index += 1
        if (index > count - 1) { index = 0 }
        this.index = index
      },
      testMsg(){
        this.$toast('提示内容')
      }
		},
		components:{
			
		}
	}
</script>


<style scoped>

</style>
