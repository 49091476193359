import Vue from 'vue'
import App from './App.vue'

import serve from '@/utils/serve'
import router from '@/router'
import store from '@/store'
// import audio from '@/assets/video/cjyl.mp3'
// import audioBg from '@/assets/video/bjyl.mp3'

require('./utils/adapter.js')(document,window)
import './assets/css/lottery.css'

import VueAxios from 'vue-axios'
import axios from "axios";
import {Button,Panel,NavBar,Form,Field,Toast,Stepper,Picker,Popup,Cell,CellGroup,RadioGroup, Radio,Dialog, Tab, Tabs,PullRefresh,Sidebar, SidebarItem } from 'vant';

Vue.use(VueAxios,axios)
Vue.use(Button)
Vue.use(Panel)
Vue.use(NavBar)
Vue.use(Form)
Vue.use(Field)
Vue.use(Toast)
Vue.use(Stepper)
Vue.use(Picker)
Vue.use(Popup)
Vue.use(Cell)
Vue.use(CellGroup)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(Dialog)
Vue.use(Tab)
Vue.use(Tabs)
Vue.use(PullRefresh)
Vue.use(Sidebar);
Vue.use(SidebarItem);

// 全局配置axios
Vue.prototype.$axios = serve
Vue.prototype.$EventBus = new Vue()
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')

// Vue.prototype.playAudio = () => {
//   let buttonAudio = document.getElementById('eventAudio');
//   buttonAudio.setAttribute('src',audio)
//   buttonAudio.play()
// }
// Vue.prototype.pauseAudio = () => {
//   let buttonAudio = document.getElementById('eventAudio');
//   buttonAudio.setAttribute('src',audio)
//   buttonAudio.pause()
// }
// Vue.prototype.playAudioBg = () => {
//   let buttonAudio = document.getElementById('eventAudioBg');
//   buttonAudio.setAttribute('src',audioBg)
//   buttonAudio.play()
// }
// Vue.prototype.pauseAudioBg = () => {
//   let buttonAudio = document.getElementById('eventAudioBg');
//   buttonAudio.setAttribute('src',audioBg)
//   buttonAudio.pause()
// }

// document.body.addEventListener('click',function( e ){
//   //alert('audio');
//   let event = e || window.event;
//   let target = event.target || event.srcElement;
//   let clickMusic = target.getAttribute('clickMusic');
//   if(clickMusic==='true') Vue.prototype.playAudio()
//   let clickMusicBg = target.getAttribute('clickMusicBg');alert('audio'+clickMusicBg);
//   if(clickMusicBg==='true') Vue.prototype.playAudioBg()
//   else return false;
// })

