import axios from "axios";
import siteinfo from '@/siteinfo'

//console.log('siteinfo:',siteinfo.api_root)
//创建一个axios实例
const serve = axios.create({
    //超时时间
    timeout:20000,
    //请求基本地址 每次请求前会自动加上此前缀 例如 'http://loaclhost:8080/api' 此处是设置了代理  https://api.365.rzico.com:9443
    baseURL:siteinfo.api_root, //'https://api.jxd.qmin365.cn:28446',//https://api.qmin365.cn' http://192.168.0.106:6888  https://api.jxd.qmin365.cn:28446
    headers: {
      //  'x-Request-with': 'XMLHttpRequest' //x-requested-with 请求头 区分ajax请求还是普通请求
    },
    withCredentials: false /*是否必须携带XMLHttpRequest 的 withCredentials 属性默认值为false。在获取同域资源时设置 withCredentials 没有影响。true：在跨域请求时，会携带用户凭证*/
})
let token=window.localStorage.getItem('token')
//console.log('token8:',token)
//if(!token||undefined==token||null==token)
//token='BearereyJhbGciOiJIUzUxMiJ9.eyJtY2hJZCI6IjEwMjAwIiwibmlja25hbWUiOiIxNTg1OTIzNjk4MSIsImV4cCI6MTY3NDQ0OTc2MiwidXNlcklkIjoiMEMyNkEwNUFFMTkyNEI0QjhDNTM5ODI3RkM2MkZGNTEiLCJ1c2VybmFtZSI6Im1jaF8xMDIwMF8xNTg1OTIzNjk4MSJ9._JpfLTbpi7LGbotEMS-KEpyMK167f11iCdKDJMtd5M7-HqN3IpxfPekJbACSMpjCtD0fp0G1twmg0nAkLRAE2w'
serve.defaults.headers.common['Authorization'] =token
//请求拦截器 在每次发起axios请求之前对我们的请求做一些设置 最常见的设置 请求头是否携带token 等
serve.interceptors.request.use(config => {
      let token =  window.localStorage.getItem('token')
       if(token){
       // config.defaults.headers['Authorization'] = token;
          // serve.defaults.headers.common['Authorization'] =token
        }
        return config
    },
    //对请求错误做点什么
    error => {
        return Promise.reject(error)
    })

//响应拦截器 指的是每次请求回来之后 对服务器响应的响应体做出处理
serve.interceptors.response.use(res => {
		// 2xx 范围内的状态码都会触发该函数。
  		// 对响应数据做点什么
        return res.data
    }
   	 // 超出 2xx 范围的状态码都会触发该函数。
 	 // 对响应错误做点什么
    ,error => {
        return Promise.reject(error)
    })
//导出默认
export default serve
